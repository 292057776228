/* Colors Neuhaus */
/* Fonts Neuhaus */
.insider-tile-container .form-input-container {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  max-width: 300px;
  -ms-flex-preferred-size: 120px;
      flex-basis: 120px; }

.insider-tile-container .icon-container {
  border-top: 1px solid #ddd; }
  @media (min-width: 544px) {
    .insider-tile-container .icon-container {
      border-top: none; } }

.insider-advantage-container .insider-advantage-icon {
  min-width: 40px; }
